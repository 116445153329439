import { gql } from "@apollo/client";


export const CONFIG_OVERRIDE_ONE = gql`
  query ConfigOverride($id: ID!) {
    ConfigOverride(id: $id) {
      id
      creator_id
      version
      config_key
      config_value
      scope
      scope_id
      reason
      created_at
      __typename
    }
  }
`;

export const CONFIG_OVERRIDE_LIST = gql`
  query allConfigOverrides(
    $page: Int
    $perPage: Int
    $sortField: String
    $sortOrder: String
    $filter: ConfigOverridesFilter
  ) {
    items: allConfigOverrides(
      page: $page
      perPage: $perPage
      sortField: $sortField
      sortOrder: $sortOrder
      filter: $filter
    ) {
      id
      version
      creator_id
      config_key
      config_value
      scope
      scope_id
      created_at
      reason
      __typename
    }
    total: _allConfigOverridesMeta(
      page: $page
      perPage: $perPage
      filter: $filter
    ) {
      count
    }
  }
`;

export const CONFIG_OVERRIDE_CREATE = gql`
  mutation CreateConfigOverride($data: ConfigOverrideCreateInput!) {
   createConfigOverride(data: $data) {
      scope
      id
      config_key
      config_value
      __typename
    }
  }
`;

export const CONFIG_OVERRIDE_DELETE = gql`
  mutation DeleteConfigOverride($id: ID!) {
    data: deleteConfigOverride(id: $id) {
      id
      config_key
      config_value
      scope
      created_at
      description
      __typename
    }
  }
`;